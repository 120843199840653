import React from 'react'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <header className="mb-12">
      <div className="text-center mb-3 w-full">
        <Link to="/"><img src={'/kamal_nasser_engrossers.png'} alt="Kamal Nasser" className="name-header-img mx-auto" /></Link>
      </div>

      <nav className="flex justify-between items-baseline">
        <p className="italic">
          <span className="line-through tracking-tight hover:no-underline">Staff Emojiologist</span> Senior Software Engineer&nbsp;
          <span className="not-italic" role="img" aria-label="ice cream emoji">🍦</span>
          at <a className="text-blue-600 hover:highlight" href="https://kmln.sr/do">DigitalOcean</a>.
        </p>
        <a className="block hover:highlight" href="https://twitter.com/kamaln7">🦜 @kamaln7</a>
      </nav>
    </header>
  )
}

export default Header
